@import "./theme.scss";

//inter font-family
@font-face {
  font-family: interBold;
  src: url("./../../../assets/fonts/inter/Inter-Bold.ttf");
}

@font-face {
  font-family: interExtraBold;
  src: url("./../../../assets/fonts/inter/Inter-ExtraBold.ttf");
}

@font-face {
  font-family: interExtraLight;
  src: url("./../../../assets/fonts/inter/Inter-ExtraLight.ttf");
}

@font-face {
  font-family: interLight;
  src: url("./../../../assets/fonts/inter/Inter-Light.ttf");
}

@font-face {
  font-family: interMedium;
  src: url("./../../../assets/fonts/inter/Inter-Medium.ttf");
}

@font-face {
  font-family: interReg;
  src: url("./../../../assets/fonts/inter/Inter-Regular.ttf");
}

@font-face {
  font-family: interSemiBold;
  src: url("./../../../assets/fonts/inter/Inter-SemiBold.ttf");
}

@font-face {
  font-family: interThin;
  src: url("./../../../assets/fonts/inter/Inter-Thin.ttf");
}

//jakarta font-family
@font-face {
  font-family: jakartaBold;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-Bold.ttf");
}

@font-face {
  font-family: jakartaExtraBold;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-ExtraBold.ttf");
}

@font-face {
  font-family: jakartaExtraLight;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-ExtraLight.ttf");
}

@font-face {
  font-family: jakartaLight;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-Light.ttf");
}

@font-face {
  font-family: jakartaMedium;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-Medium.ttf");
}

@font-face {
  font-family: jakartaReg;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-Medium.ttf");
}

@font-face {
  font-family: jakartaSemiBold;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-SemiBold.ttf");
}

//other font-family
@font-face {
  font-family: lexandReg;
  src: url("./../../../assets/fonts/Lexand/Lexend-Regular.ttf");
}

@font-face {
  font-family: lexandSemiBold;
  src: url("./../../../assets/fonts/Lexand/Lexend-SemiBold.ttf");
}

@font-face {
  font-family: lexandBold;
  src: url("./../../../assets/fonts/Lexand/Lexend-Bold.ttf");
}

@font-face {
  font-family: lexandExtraBold;
  src: url("./../../../assets/fonts/Lexand/Lexend-ExtraBold.ttf");
}

@font-face {
  font-family: lexandLigh;
  src: url("./../../../assets/fonts/Lexand/Lexend-Light.ttf");
}

@font-face {
  font-family: lexandMedium;
  src: url("./../../../assets/fonts/Lexand/Lexend-Medium.ttf");
}

@font-face {
  font-family: lexandBlack;
  src: url("./../../../assets/fonts/Lexand/Lexend-Black.ttf");
}

@font-face {
  font-family: lexandExtraLight;
  src: url("./../../../assets/fonts/Lexand/Lexend-ExtraLight.ttf");
}

@font-face {
  font-family: lexandThin;
  src: url("./../../../assets/fonts/Lexand/Lexend-Thin.ttf");
}

//website font families
@font-face {
  font-family: opensans_reg400;
  src: url("./../../../assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: opensans_semibold600;
  src: url("./../../../assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: merriweather_bold700;
  src: url("./../../../assets/fonts/Merriweather/Merriweather-Bold.ttf");
}

@font-face {
  font-family: sourceserifpro_semibold600;
  src: url("./../../../assets/fonts/Source_Serif_Pro/SourceSerifPro-SemiBold.ttf");
}

@font-face {
  font-family: lato_regular400;
  src: url("./../../../assets/fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: roboto_regular400;
  src: url("./../../../assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: nunito_sans_bold700;
  src: url("./../../../assets/fonts/Nunito_sans/NunitoSans-Bold.ttf");
}

@font-face {
  font-family: nunito_reg400;
  src: url("./../../../assets/fonts/Nunito/Nunito-Regular.ttf");
}

@font-face {
  font-family: bitter_semibold600;
  src: url("./../../../assets/fonts/Bitter/Bitter-SemiBold.ttf");
}

@font-face {
  font-family: firasans_reg400;
  src: url("./../../../assets/fonts/Fira_sans/FiraSans-Regular.ttf");
}

.cursor-pointer {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 0.1px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $white;
}

::-webkit-scrollbar {
  display: none;
}

button {
  cursor: pointer;
}

button:disabled {
  border-color: $gray_500 !important;
  color: $gray_500 !important;
  cursor: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
// input[type="number"] {
//   // -moz-appearance: textfield;
// }
input[type=file],
/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

//extra bold classes

.h1_80_extraBold {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, interExtraBold, $black_700, normal, 800);
}

.h2_60_extraBold {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, interExtraBold, $black_700, normal, 800);
}

.h3_40_extraBold {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, interExtraBold, $black_700, normal, 800);
}

.h4_30_extraBold {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, interExtraBold, $black_700, normal, 800);
}

.h5_24_extraBold {
  line-height: 34px;
  @include text-size(24px, interExtraBold, $black_700, normal, 800);
}

.h6_20_extraBold {
  line-height: 30px;
  @include text-size(20px, interExtraBold, $black_700, normal, 800);
}

.extrabold_18_regular {
  line-height: 32px;
  @include text-size(18px, interExtraBold, $black_700, normal, 800);
}

.extrabold_16_body {
  line-height: 28px;
  @include text-size(16px, interExtraBold, $black_700, normal, 800);
}

//bold classes

.h1_80_bold {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, interBold, $black_700, normal, 700);
}

.h2_60_bold {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, interBold, $black_700, normal, 700);
}

.h3_40_bold {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, interBold, $black_700, normal, 700);
}

.h4_30_bold {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, interBold, $black_700, normal, 700);
}

.h5_24_bold {
  line-height: 34px;
  @include text-size(24px, interBold, $black_700, normal, 700);
}

.h6_20_bold {
  line-height: 30px;
  @include text-size(20px, interBold, $black_700, normal, 700);
}

.bold_18_regular {
  line-height: 32px;
  @include text-size(18px, interBold, $black_700, normal, 700);
}

.bold_16_body {
  line-height: 28px;
  @include text-size(16px, interBold, $black_700, normal, 700);
}

//Semi Bold Size Classes

.h1_80_semiBold {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, interSemiBold, $black_700, normal, 600);
}

.h2_60_semiBold {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, interSemiBold, $black_700, normal, 600);
}

.h3_40_semiBold {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, interSemiBold, $black_700, normal, 600);
}

.h4_30_semiBold {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, interSemiBold, $black_700, normal, 600);
}

.h5_24_semiBold {
  line-height: 34px;
  @include text-size(24px, interSemiBold, $black_700, normal, 600);
}

.h6_20_semiBold {
  line-height: 30px;
  @include text-size(20px, interSemiBold, $black_700, normal, 600);
}

.semiBold_18_regular {
  line-height: 32px;
  @include text-size(18px, interSemiBold, $black_700, normal, 600);
}

.semiBold_16_body {
  line-height: 28px;
  @include text-size(16px, interSemiBold, $black_700, normal, 600);
}

//Medium Size Classes

.h1_80_medium {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, interMedium, $black_700, normal, 300);
}

.h2_60_medium {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, interMedium, $black_700, normal, 500);
}

.h3_40_medium {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, interMedium, $black_700, normal, 500);
}

.h4_30_medium {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, interMedium, $black_700, normal, 500);
}

.h5_24_medium {
  line-height: 34px;
  @include text-size(24px, interMedium, $black_700, normal, 500);
}

.h6_20_medium {
  line-height: 30px;
  @include text-size(20px, interMedium, $black_700, normal, 500);
}

.medium_18_regular {
  line-height: 32px;
  @include text-size(18px, interMedium, $black_700, normal, 500);
}

.medium_16_body {
  line-height: 28px;
  @include text-size(16px, interMedium, $black_700, normal, 500);
}

//Regular Size Classes

.h1_80_regular {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, interReg, $black_700, normal, 400);
}

.h2_60_regular {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, interReg, $black_700, normal, 400);
}

.h3_40_regular {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, interReg, $black_700, normal, 400);
}

.h4_30_regular {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, interReg, $black_700, normal, 400);
}

.h5_24_regular {
  line-height: 34px;
  @include text-size(24px, interReg, $black_700, normal, 400);
}

.h6_20_regular {
  line-height: 30px;
  @include text-size(20px, interReg, $black_700, normal, 400);
}

.regular_18_regular {
  line-height: 32px;
  @include text-size(18px, interReg, $black_700, normal, 400);
}

.regular_16_body {
  line-height: 28px;
  @include text-size(16px, interReg, $black_700, normal, 400);
}

//Light Size Classes

.h1_80_light {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, interLight, $black_700, normal, 300);
}

.h2_60_light {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, interLight, $black_700, normal, 300);
}

.h3_40_light {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, interLight, $black_700, normal, 300);
}

.h4_30_light {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, interLight, $black_700, normal, 300);
}

.h5_24_light {
  line-height: 34px;
  @include text-size(24px, interLight, $black_700, normal, 300);
}

.h6_20_light {
  line-height: 30px;
  @include text-size(20px, interLight, $black_700, normal, 300);
}

.light_18_regular {
  line-height: 32px;
  @include text-size(18px, interLight, $black_700, normal, 300);
}

.light_16_body {
  line-height: 28px;
  @include text-size(16px, interLight, $black_700, normal, 300);
}

@keyframes btnglowing {
  0% {
    background-color: $white;
    box-shadow: 0 0 3px $primary_blue_500;
  }

  50% {
    background-color: $white;
    box-shadow: 0 0 10px $primary_blue_500;
  }

  100% {
    background-color: $white;
    box-shadow: 0 0 3px $primary_blue_500;
  }
}

//extra light classes

.extraLight_18 {
  line-height: 32px;
  @include text-size(18px, interExtraLight, $black_700, normal, 200);
}

.extraLight_16_body {
  line-height: 32px;
  @include text-size(18px, interExtraLight, $black_700, normal, 200);
}

.filter-dialog-container {
  .mat-dialog-container {
    padding: 0px;
    border-radius: 20px;
  }
}

.bottom-sheet-height{
  .mat-bottom-sheet-container{
    height: 100vh;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
}

.slick-dots {
  position: relative !important;
  bottom: 0px !important;
}

.domain-input {
  .mat-mdc-form-field-flex {
    height: 40px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 0px !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control .mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 40px;
    @include body16r();
    color: $text_300;
    letter-spacing: 0px;
  }
}


.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0px;
  display: flex;
  // justify-content: center;
  align-items: center;
  min-height: 0px !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control,
.mat-mdc-select-trigger {
  height: 40px !important;
}

.custom-mat-form-field-size {

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control,
  .mat-mdc-select-trigger {
    font-size: 14px;
  }
}


.days_dropdown {
  .mat-mdc-text-field-wrapper {
    background-color: $white;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
      0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 4px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}



.hash-tag-popup{
  position: absolute !important;
  top: 17% !important;
  left: 14% !important;
  right: 138% !important;
}

.input_card {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.google_search {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-flex {
    height: 30px;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control,
  .mat-mdc-select-trigger {
    height: 30px !important;
  }

  .mdc-text-field {
    background: $text_50 !important;
    border-radius: 5px;
  }
}

.url_input {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
.tags{
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
.search-bar
{
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.search_domain {
  .mat-mdc-text-field-wrapper {
    background-color: $white;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.taking_input,
.location_input {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.table-section {
  table {
    mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
      background-color:var(--primary-bg-color);
      border-color:var(--primary-bg-color);
    }

    .mat-mdc-slide-toggle.mat-accent {
      --mdc-switch-selected-handle-color:var(--primary-bg-color);
      --mdc-switch-selected-track-color:var(--primary-bg-color);
      --mdc-switch-selected-hover-track-color:var(--primary-bg-color);
    }
  }
}

.business-field-section {
  mat-form-field {
    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }
  }
}

.mat-mdc-option .mdc-list-item__primary-text {
  @include body16r();
  color: $text_300 !important;
  letter-spacing: 0px !important;
  font-family: interReg !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  width: 100%
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled){
  background-color: $primary_blue_100 !important;
}

.mat-mdc-checkbox label {
  @include caption14r();
  letter-spacing: 0px !important;
  font-family: interReg !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.mat-form-field-label {
  @include caption14r();
  letter-spacing: 0px !important;
  font-family: interReg !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.whitebackgroundInput {
  .mat-mdc-text-field-wrapper {
    background: $white !important;
  }

  span.mat-mdc-select-min-line {

    @include body16r();
    color: $text_400 !important;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary_blue_500;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: $primary_blue_500;
  background-color: $primary_blue_500;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: $primary_blue_500;
}

//loader
.api_loader {
  .dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .dot {
    height: 15px;
    width: 15px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #b3d4fc;
    animation: pulse 1.5s infinite ease-in-out;
  }

  .dot:last-child {
    margin-right: 0;
  }

  .dot:nth-child(1) {
    animation-delay: -0.3s;
  }

  .dot:nth-child(2) {
    animation-delay: -0.1s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.1s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      background-color: #b3d4fc;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }

    50% {
      transform: scale(1.2);
      background-color: #6793fb;
      box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
    }

    100% {
      transform: scale(0.8);
      background-color: #b3d4fc;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }
  }
}

.mdc-switch__ripple {
  left: 0% !important;
}

.dropdown {
  .mat-mdc-select-value {
    color: white;
    @include text-size(16px, interReg, $white, normal, 700);
  }

  .mat-mdc-select-arrow {
    color: white;
    @include text-size(16px, interReg, $white, normal, 700);
  }
}

.background-white-chip {
  .mdc-text-field--filled:not {
    background-color: white !important;
  }
}

.custom-mat-form-field {
  .mat-mdc-form-field-subscript-wrapper {
    height: 0 !important;
    margin-top: 5px;
  }
}

.custom-mat-form-field-datepicker {
  .mdc-notched-outline {
    height: 80%;
  }
}

.custom-mat-datepicker-field {
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 40px;
    height: 40px;
    padding: 5px;
  }
}

.casual_dropdown {
  .mat-mdc-select-value {
    color: $text_300 !important;
    @include text-size(16px, interReg, $white, normal, 700);
  }

  .mat-mdc-select-arrow {
    color: $text_300 !important;
    @include text-size(16px, interReg, $white, normal, 700);
  }
}

.pricing_selection {
  .mat-mdc-select-value-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500 !important;
    font-family: interMedium;
  }
}

.mat-mdc-select-value-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500 !important;
  color: #121212 !important;
  font-family: interMedium;
}

.fontsize-dropdown {
  .mat-mdc-text-field-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px !important;
  }

  .mat-mdc-form-field-flex {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mat-mdc-select-trigger {
    height: 30px;
    @include text-size(18px, interMedium, $black, normal, 500);
    bottom: 6px;
  }
}

.fontsizes-dropdown {
  padding-left: 9px !important;
  padding-right: 9px !important;
  display: flex;
  // justify-content: center;
  min-height: 30px !important;
  text-align: center;
}

.editor_button_dropdonw {
  padding-left: 5px !important;
  padding-right: 5px !important;
  display: flex;
  justify-content: center;
  min-height: 30px !important;

  .mat-mdc-option .mdc-list-item__primary-text {
    @include caption14r();
    color: $text_300 !important;
    letter-spacing: 0px !important;
    font-family: interReg !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}

.editor_button {
  .mat-mdc-select-min-line {
    font-size: 14px !important;
  }
}

//this toggle button UI changes in editor version
.switch_toggle_btn {
  .switchery {
    width: 36px;
  }

  input+.switchery {
    background-color: #dfdfdf;
  }

  .switchery,
  .switchery>small {
    height: 18px;
  }

  .switchery>small {
    width: 18px;
    height: 18px;
  }

  input:checked+.switchery {
    box-shadow: none;
  }

  input:checked+.switchery>small {
    left: 18px;
  }
}

.bar {
  .mdc-slider .mdc-slider__track {
    background-color: $text_50;
  }

  .mat-mdc-slider .mdc-slider__thumb-knob {
    border-color: $white;
    box-shadow: 0px 0px 1px 0px rgba(40, 41, 61, 7);
  }
}

.outer_box {
  span.mat-mdc-select-min-line {
    @include body16r();
    color: $text_500 !important;
  }
}

.label-font-bold-14px {

  .mdc-form-field>label {
    @include caption14m();
    display: flex;
    align-items: center;
  }
}

.form-field-h-40px {
  .mdc-text-field--outlined .mdc-notched-outline {
    height: 41px;
  }

  .mdc-text-field--outlined .mdc-notched-outline {
    z-index: 1;
    height: 87%;
}

  .mat-mdc-icon-button svg {
    position: relative;
    bottom: 3px;
    right: 2px;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: 40px;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 40px;
    height: 40px;
  }
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none;
}

.simpo-mat-chip {
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
    color: $primary_blue_500;
  }

  .mat-mdc-chip-ripple {
    background-color: $primary_blue_50;
  }
}

.outlined-blue-button {
  background: var(--primary-bg-color) !important;
  background-clip: text !important; 
  -webkit-text-fill-color: transparent !important;
  position: relative;
}
.outlined-blue-button ::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1px;
  border-radius: 13px;  
  background: var(--primary-bg-color);
  mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask-composite: exclude;
}

.flat-blue-button {
  background: var(--primary-bg-color);
  color: white;
}

.mat-bottom-sheet-container {
  max-height: 90vh !important;
  padding: unset !important;
}

.lead-details-section {
  .right-side-details {
    mat-form-field {
      mat-select{
        font-size: 14px !important;
      }
      mat-mdc-select-min-line {
        font-size: 14px !important;
      }

      mat-mdc-select-value-text {
        font-size: 14px !important;
      }
    }
  }
}

.mat-mdc-text-field-wrapper{
  width: 100% !important;
  background-color: white !important;
}

.mat-mdc-select-value {
  width: 300px;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-mdc-select-arrow svg {
    fill: currentColor;
    position: absolute;
    top: 50%;
    left: -20%;
    transform: translate(-50%, -50%);
}
.custom-picker{
  .mat-mdc-form-field-subscript-wrapper{
    display: none;
  }
  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .domain-input .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control{
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}

.mat-date-range-input-wrapper {
  position: relative;
  overflow: hidden;
  max-width: calc(50% - 4px);
  width: 32%;
}
.date-picker-input {
  .mat-mdc-form-field-flex {
    height: 36px;
    display: flex;
    align-items: center;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border: 2px solid #D0D5DD;
    border-right: none;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border: 2px solid #D0D5DD;
    border-left: none;
  }
}
.invoice-mat-dropdown{
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border: 2px solid #D0D5DD;
    border-right: none;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border: 2px solid #D0D5DD;
    border-left: none;
  }
}
.invoice-category-dropdown{
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border: 2px solid #D0D5DD;
    border-right: none;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border: 2px solid #D0D5DD;
    border-left: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.invoice-product-dropdown{
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border: 2px solid #D0D5DD;
    border-right: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border: 2px solid #D0D5DD;
    border-left: none;
  }
}
.mat-date-range-input-container{
  width: 65%;
}
.mdc-data-table__row:not(.mdc-data-table__row--selected):hover{
  background-color: #fafafb !important;
}

.mdc-evolution-chip-set__chips{
  flex-flow: nowrap !important;
  width: 100% !important;
  overflow-x: scroll !important;
}


@media only screen and (max-width: 475px) {
  .add-business-section {
    padding: 18px 0px 22px 0px;
    text-align: center;
    border-radius: 10px;
    margin-left: 1px ;
    margin-right: -8px;
    background-color: rgb(4, 150, 255);
    cursor: pointer;
    font-family: lexandSemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    border: none;
    margin-bottom: 4px;
    height: 40px;
  }
}

.search-filter-main-section {
  display: flex;
  align-items: center;
}
.label-style
{
  position: absolute;
  top: 21%;
  right: 29%;
  border-radius: 20px;
}
.hash-tag-cid
{
  margin-left: 54% !important;
  position: absolute !important;
  top: 37% !important;
  right: 3%;
  margin-top: 0px;
}

.search-filter-main-section .filter-section {
  font-family: interMedium;
  font-size: 14px;
  color: #344054;
  font-style: normal;
  font-weight: 500;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 8px 20px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  gap: 10px;
  margin-left: 3%;
}


.task-repeat-popup{
  background: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  padding: 20px;
  z-index: 101;
  width: 100%;
  height: 370px;

}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  right: 0;
}
.invoice-filter{
  max-width: 100vw !important;
  max-height: 100vh;
  position: absolute !important;
  top: 41%;
  right: 3%;
}
.trim-text {
  text-align: left !important;
  display: -webkit-box!important;
  white-space: wrap !important;
  overflow: hidden !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis !important;
  word-break: break-all;
}

// .cdk-overlay-pane {
//   bottom: auto !important;
//   top: 16% !important;
//   left: 25% !important;
// }
.description{
  .angular-editor-toolbar{
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap !important;
    gap: 5px !important;

    .angular-editor-toolbar-set{
      display: flex !important;
      gap: 3px !important;
    }
  }
}
.datetime{
  .cdk-overlay-pane{
    height: 100%;
    width: 100%;
    position: relative !important;
    left: 43% !important;
    top: 19% !important;
  }
}

.manageFields{
  position: relative !important;
    top: 0 !important;
    left: 33% !important;
}

.date
{
  .mat-date-range-input-container {
    width: 70%;
    justify-content: space-between !important;
  }
}

.date-range-section {
  .mat-date-range-input-container {
    width: 100%;
  }

  .mat-date-range-input-wrapper {
    position: relative;
    overflow: hidden;
    max-width: calc(50% - 4px);
    width: calc(50% - 4px) !important;
  }
}


// .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch{
//   ;
// }
// .cdk-overlay-pane
// {
//   .open-post
//   {
//     max-width:50vw !important;
//     max-height: 49.5vh !important;
//   }
// }